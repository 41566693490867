$blue: #0052ff !default;
$red: #ff4133 !default;
$cyan: #89d9e2 !default;
$yellow: #fec504 !default;

$enable-shadows: true;

@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

.fondoGradientInicio {
  background: $blue;
  background: linear-gradient(90deg, $blue 0%, $cyan 100%);
}

p {
  line-height: 2rem;
}

.fondoVideo {
  background: $blue;
  background: linear-gradient(90deg, $blue 0%, $cyan 50%, $blue 100%);
}